import React from 'react';

const HowWeDoIt = () => {
    return (
        <div className="w-full bg-gradient-to-b from-purple-800 to-purple-900 py-16 px-4 text-white">
            <h1 className="text-center text-4xl font-bold mb-12">How We Do It</h1>
            <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-12">
                <div className="flex flex-col font-semibold items-center text-center space-y-6 transition-transform transform hover:scale-105 bg-gradient-to-r from-purple-700 via-purple-800 to-purple-900 p-6 rounded-lg shadow-2xl border-4 border-white">
                    <img src="/img/strategy.png" alt="Strategy" className="w-[150px] h-[150px] mb-4 rounded-full shadow-xl" />
                    <h2 className="text-3xl font-semibold text-white mb-2 hover:text-pink-200 transition-colors duration-300">01 Strategy</h2>
                    <ul className="list-none space-y-2 text-left text-white">
                        <li className="hover:text-pink-100 transition-colors duration-300">1. Conduct in-depth consultations to understand client goals, target audience, and business needs.</li>
                        <li className="hover:text-pink-100 transition-colors duration-300">2. Perform competitive analysis and market research to inform project direction.</li>
                        <li className="hover:text-pink-100 transition-colors duration-300">3. Develop a comprehensive project roadmap, outlining timelines, milestones, and deliverables.</li>
                    </ul>
                </div>
                <div className="flex flex-col font-semibold items-center text-center space-y-6 transition-transform transform hover:scale-105 bg-gradient-to-r from-blue-600 via-blue-700 to-purple-800 p-6 rounded-lg shadow-2xl border-4 border-white">
                    <img src="/img/design.png" alt="Design" className="w-[150px] h-[150px] mb-4 rounded-full shadow-xl" />
                    <h2 className="text-3xl font-semibold text-white mb-2 hover:text-blue-200 transition-colors duration-300">02 Design</h2>
                    <ul className="list-none space-y-2 text-left text-white">
                        <li className="hover:text-blue-100 transition-colors duration-300">1. Create wireframes and prototypes to visualize the project structure and flow.</li>
                        <li className="hover:text-blue-100 transition-colors duration-300">2. Develop aesthetically pleasing and intuitive designs that align with brand identity and user expectations.</li>
                        <li className="hover:text-blue-100 transition-colors duration-300">3. Ensure responsive and accessible designs for an optimal user experience across all devices.</li>
                    </ul>
                </div>
                <div className="flex flex-col font-semibold items-center text-center space-y-6 transition-transform transform hover:scale-105 bg-gradient-to-r from-pink-400 via-pink-500 to-red-500 p-6 rounded-lg shadow-2xl border-4 border-white">
                    <img src="/img/development.png" alt="Development" className="w-[150px] h-[150px] mb-4 rounded-full shadow-xl" />
                    <h2 className="text-3xl font-semibold text-white mb-2 hover:text-pink-200 transition-colors duration-300">03 Development</h2>
                    <ul className="list-none space-y-2 text-left text-white">
                        <li className="hover:text-pink-100 transition-colors duration-300">1. Utilize modern technologies and frameworks to build scalable and high-performance solutions.</li>
                        <li className="hover:text-pink-100 transition-colors duration-300">2. Implement agile development practices to ensure flexibility and continuous improvement.</li>
                        <li className="hover:text-pink-100 transition-colors duration-300">3. Conduct rigorous testing to identify and resolve issues, ensuring a smooth and reliable final product.</li>
                    </ul>
                </div>
                <div className="flex flex-col font-semibold items-center text-center space-y-6 transition-transform transform hover:scale-105 bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 p-6 rounded-lg shadow-2xl border-4 border-white">
                    <img src="/img/support.png" alt="Support" className="w-[150px] h-[150px] mb-4 rounded-full shadow-xl" />
                    <h2 className="text-3xl font-semibold text-white mb-2 hover:text-teal-200 transition-colors duration-300">04 Support</h2>
                    <ul className="list-none space-y-2 text-left text-white">
                        <li className="hover:text-teal-100 transition-colors duration-300">1. Provide continuous monitoring and maintenance to keep your solution up-to-date and secure.</li>
                        <li className="hover:text-teal-100 transition-colors duration-300">2. Offer comprehensive support services, including troubleshooting, updates, and enhancements.</li>
                        <li className="hover:text-teal-100 transition-colors duration-300">3. Gather user feedback and analytics to make data-driven improvements and ensure long-term success.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default HowWeDoIt;
