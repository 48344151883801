import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

const Navbar = () => {
    const [nav, setNav] = useState(false);

    const handleNav = () => {
        setNav(!nav);
    };

    return (
        <div className="flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white">
            {/* Brand Logo */}
            <img src="/img/logo 1.svg" alt="Tech With Molato" className="w-32 h-auto" />
            
            <ul className="hidden md:flex">
                <li className="p-4 hover:text-[#b57be6] transition duration-300 ease-in-out font-semibold uppercase">Home</li>
                <li className="p-4 hover:text-[#b57be6] transition duration-300 ease-in-out font-semibold uppercase">What We Do</li>
                <li className="p-4 hover:text-[#b57be6] transition duration-300 ease-in-out font-semibold uppercase">Our Work</li>
                <li className="p-4 hover:text-[#b57be6] transition duration-300 ease-in-out font-semibold uppercase">Contact</li>
            </ul>
            
            <div onClick={handleNav} className="block md:hidden">
                {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
            </div>
            
            <div 
                className={nav ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-cover bg-center bg-no-repeat ease-in-out duration-500" : "fixed left-[-100%]"} 
                style={{ backgroundImage: "url('/img/site-bg.jpg')" }}
            >
                {/* Brand Logo */}
                <img src="/img/logo 1.svg" alt="Tech With Molato" className="w-32 h-auto m-4" />
                
                <ul className="uppercase p-4">
                    <li className="p-4 border-b border-gray-600 hover:text-[#00df9a] transition duration-300 ease-in-out">Home</li>
                    <li className="p-4 border-b border-gray-600 hover:text-[#00df9a] transition duration-300 ease-in-out">What We Do</li>
                    <li className="p-4 border-b border-gray-600 hover:text-[#00df9a] transition duration-300 ease-in-out">Our Work</li>
                    <li className="p-4 hover:text-[#00df9a] transition duration-300 ease-in-out">Contact</li>
                </ul>
            </div>
        </div>
    );
}

export default Navbar;
