import React from "react";
import { motion } from "framer-motion";

// Animation variants for framer-motion
const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

const textHoverEffect = {
    hover: {
        scale: 1.1,
        color: "#7a33a8",
        transition: { duration: 0.3 }
    }
};

const WhatWeDo = () => {
    return (
        <div className="w-full bg-white py-16 px-4">
            {/* Center the h1 text */}
            <motion.h1
                className="text-center text-4xl font-bold mb-8 text-[#ae59f3] transition duration-900 ease-in-out"
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
            >
                What We Do
            </motion.h1>
            <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8">
                {/* Main large image */}
                <div className="flex flex-col">
                    <motion.img
                        src="/img/webdevelopment.jpg"
                        alt="Web Development"
                        className="w-full h-[400px] object-cover mb-6 rounded-lg shadow-lg transition-transform duration-900 ease-in-out transform hover:scale-105"
                        initial="hidden"
                        animate="visible"
                        variants={fadeInUp}
                    />

                    {/* Container for smaller images */}
                    <div className="flex flex-col md:flex-row gap-4">
                        <motion.img
                            src="/img/softwaredevelopment.jpg"
                            alt="Software Development"
                            className="w-full md:w-1/2 h-[200px] object-cover rounded-lg shadow-lg transition-transform duration-900 ease-in-out transform hover:scale-105"
                            initial="hidden"
                            animate="visible"
                            variants={fadeInUp}
                        />
                        <motion.img
                            src="/img/webhosting.jpg"
                            alt="Web Hosting"
                            className="w-full md:w-1/2 h-[200px] object-cover rounded-lg shadow-lg transition-transform duration-900 ease-in-out transform hover:scale-105"
                            initial="hidden"
                            animate="visible"
                            variants={fadeInUp}
                        />
                    </div>
                </div>

                {/* Content */}
                <div className="flex flex-col justify-center space-y-8">
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={fadeInUp}
                    >
                        <motion.h2
                            className="text-2xl font-bold py-2 text-[#ae59f3] transition duration-900 ease-in-out"
                            whileHover={textHoverEffect.hover}
                        >
                            Web Hosting
                        </motion.h2>
                        <p className="font-semibold transition duration-900 ease-in-out hover:text-[#7a33a8]">
                            Reliable and secure web hosting services to ensure your website is always accessible and performing at its best.
                        </p>
                    </motion.div>
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={fadeInUp}
                    >
                        <motion.h2
                            className="text-2xl font-bold py-2 text-[#ae59f3] transition duration-900 ease-in-out"
                            whileHover={textHoverEffect.hover}
                        >
                            Web Development
                        </motion.h2>
                        <p className="font-semibold transition duration-900 ease-in-out hover:text-[#7a33a8]">
                            Creating responsive, user-friendly websites that captivate your audience and drive engagement.
                        </p>
                    </motion.div>
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={fadeInUp}
                    >
                        <motion.h2
                            className="text-2xl font-bold py-2 text-[#ae59f3] transition duration-900 ease-in-out"
                            whileHover={textHoverEffect.hover}
                        >
                            Custom Web Development
                        </motion.h2>
                        <p className="font-semibold transition duration-900 ease-in-out hover:text-[#7a33a8]">
                            Tailoring unique web solutions to meet your specific business needs, from e-commerce platforms to content management systems.
                        </p>
                    </motion.div>
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={fadeInUp}
                    >
                        <motion.h2
                            className="text-2xl font-bold py-2 text-[#ae59f3] transition duration-900 ease-in-out"
                            whileHover={textHoverEffect.hover}
                        >
                            Software Development
                        </motion.h2>
                        <p className="font-semibold transition duration-900 ease-in-out hover:text-[#7a33a8]">
                            Crafting powerful software applications designed to streamline operations and enhance productivity across various industries.
                        </p>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default WhatWeDo;
