import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from '../variants';
import { TypeAnimation } from 'react-type-animation';

const Hero = () => {
    return (
        <div className="text-[#4c1e77] flex flex-col md:flex-row items-center justify-between h-screen w-full max-w-[1240px] mx-auto px-4" >
            <div className="text-left flex flex-col justify-center h-full md:w-1/2 p-12">
                <p className="text-white font-bold p-2">Empower Your Business with Innovative Web Solutions</p>
                <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6 text-white">
                     with Cutting-Edge Technology
                </h1>
                <motion.div 
                    variants={fadeIn('up', 0.3)} 
                    initial='hidden' 
                    whileInView={'show'} 
                    viewport={{ once: false, amount: 0.7 }} 
                    className='mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]'
                >
                    <span className='text-white mr-4 md:text-1xl sm:text-4xl text-xl font-bold'></span>
                    <TypeAnimation 
                        sequence={['Web Development', 2000, 'Software Development', 2000, 'Domain & Web Hosting', 2000]} 
                        speed={50} 
                        className='text-[#b580e7] md:text-1xl sm:text-4xl text-xl' 
                        wrapper='span' 
                        repeat={Infinity} 
                    />
                </motion.div>
                <p className="md:text-2xl text-xl font-bold text-[#ecf3f1]">Boost Your Revenue with Comprehensive Web Development, Hosting, and Software Solutions</p>
                <button className="ml-0 w-[200px] rounded-md font-medium my-6 mx-auto py-3 bg-gradient-to-r from-purple-100 via-purple-300 to-purple-100 text-purple-800 shadow-lg transition-transform duration-300 ease-in-out hover:scale-105 hover:bg-gradient-to-r hover:from-purple-200 hover:to-purple-400 hover:shadow-xl">
                Get started!
                </button>

            </div>
            <div className="mt-8 md:mt-0 md:w-1/2 h-full flex justify-center items-center">
                <img src="/img/hero.png" alt="Hero" className="w-[100%] h-auto object-cover" />
            </div>
        </div>
    );
};

export default Hero;
