import React from 'react';
import Navbar from '../../tech_with_molato/src/components/Navbar';
import './App.css'; // Import other necessary styles
import Hero from '../../tech_with_molato/src/components/Hero';
import WhatWeDo from '../../tech_with_molato/src/components/WhatWeDo';
import HowWeDoIt from './components/HowWeDoIt';
function App() {
  return (
    <div className="relative min-h-screen bg-gradient-to-r from-purple-800 via-purple-500 to-purple-800">
      <Navbar />
      <Hero />
      <WhatWeDo />
      <HowWeDoIt />

    </div>
  );
}

export default App;
